import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useHomePageHeader = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: RO } }
      ) {
        homePageFields {
          panelsSection {
            panel {
              title
              subtitle
              notes
              button {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
          }
          whiteActionPanels {
            panel0 {
              title
              link {
                target
                title
                url
              }
              subtitle
              image {
                altText
                localFile {
                  url
                }
              }
            }
            title
            panel1 {
              title
              subtitle
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
            panel2 {
              title
              subtitle
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: EN } }
      ) {
        homePageFields {
          panelsSection {
            panel {
              title
              subtitle
              notes
              button {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
          }
          whiteActionPanels {
            panel0 {
              title
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
            title
            panel1 {
              title
              subtitle
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
            panel2 {
              title
              subtitle
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const page = {
    en: wpPageEn,
    ro: wpPageRo,
  }
  return page[language]?.homePageFields || wpPageRo?.homePageFields
}
