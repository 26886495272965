import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useHomepageConfigSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageRo, wpPageEn } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: RO } }
      ) {
        homePageFields {
          middleSection {
            backgroundImage {
              sourceUrl
            }
            title
            sectionNotes
            panel1 {
              title
              note
              link {
                target
                title
                url
              }
              image {
                sourceUrl
              }
            }
            panel2 {
              note
              title
              link {
                target
                title
                url
              }
              image {
                sourceUrl
              }
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: EN } }
      ) {
        homePageFields {
          middleSection {
            backgroundImage {
              sourceUrl
            }
            title
            sectionNotes
            panel1 {
              title
              note
              link {
                target
                title
                url
              }
              image {
                sourceUrl
              }
            }
            panel2 {
              note
              title
              link {
                target
                title
                url
              }
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const page = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    page[language]?.homePageFields?.middleSection ||
    wpPageRo?.homePageFields?.middleSection
  )
}
