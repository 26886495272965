import React from "react"
import "./HomePageBrochure.scss"
import {useHomePageBrochure} from "../../hooks/HomePage/useHomePageBrochure";

const HomePageBrochure = () => {
    const { title, icons } = useHomePageBrochure()
    console.log(icons)

    return (
        <div className="homePageBrochureSection">
            <div className="container">
                <div className="container-header mb-5">
                    {title && (
                        <h2 className="title-text text-center">
                            <strong>{title}</strong>
                        </h2>
                    )}
                </div>
                {icons && (
                    <div className="container-body row">
                        {icons.map((groupNumber, index) => (
                            <div key={groupNumber} className="col-md-2 text-center">
                                <a href={groupNumber.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        className={`col-md-8 col-sm-8 col-4 ${groupNumber === 1 ? 'engl' : ''}`}
                                        src={groupNumber.icon.sourceUrl}
                                        alt={groupNumber.icon.altText}
                                        style={{
                                            width: (index === icons.length - 2 || index === icons.length - 1) ? '65px' : '150',
                                            height: (index === icons.length - 2 || index === icons.length - 1) ? '65px' : '150px',
                                            marginTop: (index === icons.length - 2 || index === icons.length - 1) ? '45px' : '0',
                                            marginBottom: (index === icons.length - 2 || index === icons.length - 1) ? '40px' : '0',
                                        }}
                                    />
                                    <div className="titleContent">{groupNumber.title}</div>
                                    {groupNumber.subtitle && (
                                        <div className="titleContent">{groupNumber.subtitle}</div>
                                    )}
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

HomePageBrochure.propTypes = {}

export default HomePageBrochure
