import React from "react"
import PropTypes from "prop-types"
import ConfigPanel from "../ConfigPanel/ConfigPanel"
import { useHomepageConfigSection } from "../../hooks/HomePage/useHomePageConfigSection"
import "./HomePageConfigSection.scss"

const HomePageConfigSection = ({ location }) => {
  const { backgroundImage, title, sectionNotes, panel1, panel2 } =
    useHomepageConfigSection()
  return (
    <div className="homePageConfigSection">
      <div
        className="background"
        style={{ backgroundImage: `url(${backgroundImage?.sourceUrl})` }}
      >
        <div className="container">
          {title && (
            <div
              className="container-header" // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}

          <div className="container-content row ">
            <div className="panels-container col-12 col-xl-8 order-xl-1 ">
              {panel1 && (
                <ConfigPanel
                  link={{
                    url: panel1.link?.url,
                    target: panel1.link?.target,
                    title: panel1.link?.title,
                    location: { pathname: "/residential/" },
                  }}
                  title={panel1.title || ""}
                  description={panel1.note || ""}
                  icon={panel1.image?.sourceUrl || ""}
                />
              )}
              {panel2 && (
                <ConfigPanel
                  link={{
                    url: panel2.link?.url,
                    target: panel2.link?.target,
                    title: panel2.link?.title,
                    location: { location },
                  }}
                  title={panel2.title || ""}
                  description={panel2.note || ""}
                  icon={panel2.image?.sourceUrl || ""}
                />
              )}
            </div>

            {sectionNotes && (
              <div
                className="infoText-container col-12 col-xl-4 order-xl-0 "
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sectionNotes,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

HomePageConfigSection.defaultProps = {
  location: {},
}
HomePageConfigSection.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    href: PropTypes.string,
  }),
}

export default HomePageConfigSection
