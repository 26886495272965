import React from "react"
import PropTypes from "prop-types"
import EnsLink from "../EnsLink/EnsLink"
import EnsButton from "../EnsButton/EnsButton"
import "./ConfigPanel.scss"

const ConfigPanel = ({ title, icon, link }) => {
  return (
    <div className="config-panel">
      <div className="styled-border">
        <div
          className="panel-icon"
          style={{ backgroundImage: `url('${icon}')` }}
        />
        {title && (
          <div
            className="titleContent"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        <EnsLink
          link={{ url: link.url, target: link.target }}
          prevLink={link.location?.pathname}
        >
          <EnsButton variant="primary">{link.title}</EnsButton>
        </EnsLink>
      </div>
    </div>
  )
}

ConfigPanel.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.shape({ pathname: PropTypes.string }),
  }).isRequired,
}

export default ConfigPanel
