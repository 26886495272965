import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useAllVideos = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { allWpVideoRo, allWpVideoEn }= useStaticQuery(graphql`
    {
      allWpVideoRo: allWpVideo(
        limit: 3
        sort: { fields: [date], order: DESC }
        filter: { language: { code: { eq: RO } } }
      ) {
        nodes {
          id
          title
          content
          date(formatString: "MMMM DD, YYYY")
          link
          postExtraData {
            dataAboutPost {
              url
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      allWpVideoEn: allWpVideo(
        limit: 3
        sort: { fields: [date], order: DESC }
        filter: { language: { code: { eq: EN } } }
      ) {
        nodes {
          id
          title
          content
          date(formatString: "MMMM DD, YYYY")
          link
          postExtraData {
            dataAboutPost {
              url
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const videos = {
    en: allWpVideoEn,
    ro: allWpVideoRo,
  }
  return videos[language].nodes || allWpVideoRO.nodes
}
