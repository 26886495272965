/* eslint-disable no-return-assign */
import React, { useState } from "react"
import { useHomePageHeader } from "../../hooks/HomePage/useHomePageHeader"
import Header from "../Header/Header"
import EnsLink from "../EnsLink/EnsLink"
import EnsButton from "../EnsButton/EnsButton"
import "./HomePageHeaderAdvertisement.scss"
import "../HomePageServiceSection/HomePageServiceSection.scss"

const HomePageHeaderAdvertisement = () => {
    const { panelsSection, whiteActionPanels } = useHomePageHeader()
    const [hoveredPanel, setHoveredPanel] = useState(null);

    const handleMouseHoverEvent = (panel) => {
        panel ? setHoveredPanel(panel) : setHoveredPanel(null);
    };

    const changeBackgroundImage = (panel , imageUrl) => {
        if (panel === hoveredPanel) {
            return {
                backgroundImage: `url(${imageUrl})`
            };
        }
        return {};
    };

    return (
        <div className="homepage-header">
            <Header className="header-over" />
            {whiteActionPanels && (
                <div className="gov-programs">
                    <div className="own-contribution-panel">
                        <div className="own-contribution">
                            {whiteActionPanels.panel0 && (
                                <div className="program"
                                     style={changeBackgroundImage('panel0', whiteActionPanels.panel0?.image?.localFile?.url)}
                                     onMouseEnter={() => handleMouseHoverEvent('panel0')}
                                     onMouseLeave={handleMouseHoverEvent}>
                                    <EnsLink
                                        link={{
                                            url: whiteActionPanels.panel0.link?.url,
                                            target: whiteActionPanels.panel0.link?.target,
                                        }}
                                    >
                                        <div className="content-wrapper">
                                            {whiteActionPanels.panel0.title && (
                                                <p className="h4" style={{marginBottom:"2rem"}}>{whiteActionPanels.panel1.title}</p>
                                            )}
                                            {whiteActionPanels.panel0.subtitle && (
                                                <>
                                                    {whiteActionPanels.panel0.subtitle.split('|').map((text, index) => (
                                                        <p style={{marginBottom: '1rem'}}>
                                                            {text.trim()}
                                                        </p>
                                                    ))}
                                                </>
                                            )}
                                            <EnsButton variant="ghost-link">
                                                {whiteActionPanels.panel0.link?.title}
                                            </EnsButton>
                                        </div>
                                    </EnsLink>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="gov-panel">
                        <div className="title">
                            {whiteActionPanels.title && <h1>{whiteActionPanels.title}</h1>}
                        </div>
                        {whiteActionPanels.panel1 && (
                            <div className="program"
                                 style={changeBackgroundImage('panel1', whiteActionPanels.panel1?.image?.localFile?.url)}
                                 onMouseEnter={() => handleMouseHoverEvent('panel1')}
                                 onMouseLeave={handleMouseHoverEvent}>
                                <EnsLink
                                    link={{
                                        url: whiteActionPanels.panel1.link?.url,
                                        target: whiteActionPanels.panel1.link?.target,
                                    }}
                                >
                                    <div className="content-wrapper">
                                        {whiteActionPanels.panel1.title && (
                                            <p className="h4 mb-4">{whiteActionPanels.panel1.title}</p>
                                        )}
                                        {whiteActionPanels.panel1.subtitle && (
                                            <p>{whiteActionPanels.panel1.subtitle}</p>
                                        )}
                                        <EnsButton variant="ghost-link">
                                            {whiteActionPanels.panel1.link?.title}
                                        </EnsButton>
                                    </div>
                                </EnsLink>
                            </div>
                        )}
                        {whiteActionPanels.panel2 && (
                            <div className="program"
                                 style={changeBackgroundImage('panel2', whiteActionPanels.panel2?.image?.localFile?.url)}
                                 onMouseEnter={() => handleMouseHoverEvent('panel2')}
                                 onMouseLeave={handleMouseHoverEvent}>
                                <EnsLink
                                    link={{
                                        url: whiteActionPanels.panel2.link?.url,
                                        target: whiteActionPanels.panel2.link?.target,
                                    }}
                                >
                                    <div className="content-wrapper">
                                        {whiteActionPanels.panel2.title && (
                                            <h4>{whiteActionPanels.panel2.title}</h4>
                                        )}
                                        {whiteActionPanels.panel2.subtitle && (
                                            <p>{whiteActionPanels.panel2.subtitle}</p>
                                        )}
                                        <EnsButton variant="ghost-link">
                                            {whiteActionPanels.panel2.link?.title}
                                        </EnsButton>
                                    </div>
                                </EnsLink>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

HomePageHeaderAdvertisement.propTypes = {}

export default HomePageHeaderAdvertisement
