/* eslint-disable no-return-assign */
import React from "react"
import PropTypes from "prop-types"
import { uniqueId } from "lodash"
import { useHomePageHeader } from "../../hooks/HomePage/useHomePageHeader"
import Header from "../Header/Header"
import EnsLink from "../EnsLink/EnsLink"
import EnsButton from "../EnsButton/EnsButton"
import "./HomePageHeader.scss"
import "../HomePageServiceSection/HomePageServiceSection.scss"

const HomePageHeader = ({ location }) => {
  const { panelsSection, whiteActionPanels } = useHomePageHeader()

  return (
    <div className="homepage-header">
      <Header className="header-over" location={location} />
      <div className="ensys-panels">
        <div className="panels-container">
          <ul>
            {panelsSection &&
              panelsSection.length &&
              panelsSection.map(p => {
                const {
                  panel: {
                    title,
                    subtitle,
                    notes,
                    button,
                    image: {
                      localFile: { url: imageUrl },
                    },
                  },
                } = p
                return (
                  <li
                    className="header-panel"
                    key={uniqueId("_panel")}
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="panel-content">
                      <div className="panel-hovered">
                        {title && <h1 className="panel-title">{title}</h1>}
                        {subtitle && (
                          <h2 className="panel-subtitle">{subtitle}</h2>
                        )}
                        {button && button.title && (
                          <EnsLink
                            link={{ url: button.url, target: button?.target }}
                          >
                            <EnsButton
                              variant="secondary"
                              className="cta-button"
                            >
                              {button.title}
                            </EnsButton>
                          </EnsLink>
                        )}
                        {notes && (
                          <div
                            className="panel-info"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: notes,
                            }}
                          />
                        )}
                      </div>
                      <div className="panel-just-title">
                        {title && <h1 className="panel-title">{title}</h1>}
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

HomePageHeader.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default HomePageHeader
