import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useHomePageBrochure = () => {
    const { i18n } = useTranslation()
    const { language } = i18n
    const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: {templateName: {eq: "Homepage"}}
        language: {code: {eq: RO}}
       ) {        
        homePageFields {
          brochuresSection {
            title
            icons {
              title
              subtitle
              link
              icon {
                altText
                sourceUrl
                title
              }
            }
          }
        }
      }
      wpPage(template: {templateName: {eq: "Homepage"}}, language: {code: {eq: EN}}) {
        homePageFields {
          brochuresSection {
            title
            icons {
              title
              subtitle
              link
              icon {
                altText
                sourceUrl
                title
              }
            }
          }
        }
      }
    }
  `)

    const page = {
        en: wpPageEn,
        ro: wpPageRo,
    }

    return page[language]?.homePageFields.brochuresSection || wpPageRo?.homePageFields.brochuresSection
}
